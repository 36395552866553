import { createStore } from "redux";

const initialState = {
  posts: [
    { id: 1, title: "Test Post" },
    { id: 2, title: "Test Post 2" },
  ],
  challengeTypes: new Array(),
  challenges: new Array(),
  currentChallenges: new Array(),
  currentChallenge: 0,
};

const reducer = (state = initialState, action) => {
  if (action.type === "ADD_POST") {
    return Object.assign({}, state, {
      posts: state.posts.concat(action.payload),
    });
  }

  if (action.type === "SET_CHALLENGTYPES") {
    state.challengeTypes = new Array();
    var newVal = state.challengeTypes.concat(action.payload);

    localStorage.setItem('ChallengTypes', JSON.stringify(newVal));
    return Object.assign({}, state, {
      challengeTypes: state.challengeTypes.concat(action.payload),
    });
  }

  if (action.type === "SET_CHALLENGES") {
    state.challenges = new Array();
    var newVal = state.challenges.concat(action.payload);

    localStorage.setItem('Challenges', JSON.stringify(newVal));
    return Object.assign({}, state, {
      challenges: state.challenges.concat(action.payload),
    });
  }

  if (action.type === "SET_CURRENT_CHALLENGES") {
    state.currentChallenges = new Array();
    var newVal = state.currentChallenges.concat(action.payload);

    localStorage.setItem('CurrentChallenges', JSON.stringify(newVal));
    return Object.assign({}, state, {
      currentChallenges: newVal,
    });
  }

  return state;
};

const store = createStore(reducer);

export default store;
