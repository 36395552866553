import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function ChallengeTypes() {
  const dispatch = useDispatch();

  const [localChallenges, setLocalChallenges] = useState([]);
  const FILE = "./challenge.json";

  const fetchPostLocalChallenges = () => {
    fetch(FILE, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);

        myJson.sort(
          (a, b) => parseInt(a.challengeType) - parseInt(b.challengeType)
        );

        setLocalChallenges(myJson);
      });
  };
  useEffect(() => {
    fetchPostLocalChallenges();
  }, []);
  useEffect(() => {
    dispatch({ type: "SET_CHALLENGES", payload: localChallenges });
    dispatch({ type: "SET_CURRENT_CHALLENGES", payload: localChallenges });
  }, [localChallenges]);

  // CHALLENGE TYPES
  const [localChallengTypes, setLocalChallengeTypes] = useState([]);
  const TYPEFILE = "./challengeTypes.json";

  const fetchLocalChallengTypes = () => {
    fetch(TYPEFILE, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        myJson.sort(function (a, b) {
          if (a.challengeType < b.challengeType) {
            return -1;
          }
          if (a.challengeType > b.challengeType) {
            return 1;
          }
          return 0;
        });

        console.log(myJson);
        setLocalChallengeTypes(myJson);
      });
  };
  useEffect(() => {
    fetchLocalChallengTypes();
  }, []);
  useEffect(() => {
    dispatch({ type: "SET_CHALLENGTYPES", payload: localChallengTypes });
  }, [localChallengTypes]);
}
