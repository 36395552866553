import React from "react";
import { ReactComponent as RulefactoryLogo } from "../images/rulefactory.svg";


class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="true-footer">
          <h5>Ein Spiel von</h5>

          <a href="https://rulefactory.ch" target="_blank">
            <RulefactoryLogo className="rulefactory"></RulefactoryLogo>
          </a>
        </footer>
      </>
    );
  }
}

export default Footer;
