import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Info } from "../images/info.svg";
import { ReactComponent as Close } from "../images/close.svg";

export default function ChallengeTypes({ chName }) {
  const ch = useSelector((state) => state.challengeTypes);
  const [title, getTitle] = useState("Title");
  const [content, getContent] = useState("Content");

  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    SetType();
  }, [chName]);

  function SetType() {
    if (ch.length === 0) return;

    var index = ch.findIndex(
      (item) =>
        // item["toolset-meta"]["challenge-details"]["challenge-typ"]["raw"] === chName
        item.challengeType ===
        chName
    );
    var challenge = ch[index];

    console.log("new Data: " + challenge.title);
    getTitle(challenge.title);
    getContent(challenge.content);
  }

  return (
    <>
      <a onClick={toggleMenu} className="info-icon">
        <Info />
      </a>

      {openMenu ? (
        <div className="overlay">
          <div className="info" key={chName}>
            <a onClick={toggleMenu} className="close">
              <Close />
            </a>
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      ) : null}
    </>
  );
}
