import React, { useState, useEffect } from "react";
import Button from "./Button";
import Card from "./Card";
import MailButton from "./MailButton";
import Footer from "./Footer";
import ChallengeFilter from "./ChallengeFilter";
// import InstallPWA from "./InstallPWA";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as RandomIcon } from "../images/random.svg";
import { ReactComponent as QuizshowIcon } from "../images/quizshow.svg";
import { ReactComponent as PitchIcon } from "../images/pitch.svg";
import { ReactComponent as PresentationIcon } from "../images/presentation.svg";
import { ReactComponent as StammtischIcon } from "../images/stammtisch.svg";
import { ReactComponent as InstantStoryIcon } from "../images/story.svg";
import { ReactComponent as LückeIcon } from "../images/blank.svg";

// import { useSelector } from "react-redux";

var challenge;
let challengeText;
let challengePrompt;
let challengeStatus;
let challengeType;
var randomInt;
var challengeIndex = 0;

export default function WortspielCard() {
  const ch = useSelector((state) => state.currentChallenges);
  const allChallenges = useSelector((state) => state.challenges);
  const [chText, getChallenge] = useState("Challenge");
  const [chType, getChallengeType] = useState("ChallengeType");
  const dispatch = useDispatch();

  useEffect(() => {
    SetChallenge();
  }, [allChallenges]);

  function reloadFunction() {
    dispatch({ type: "SET_CURRENT_CHALLENGES", payload: allChallenges });
    callNextFunction();
  }

  function callNextFunction() {
    // This function should be called after the state has been updated
    // Perform actions that rely on the updated state here
    GetNextChallengeOfType(challengeType);
  }

  function SetChallenge() {
    if (ch.length === 0) return;

    randomInt = Math.floor(Math.random() * ch.length);
    challenge = ch[randomInt]; //Object.keys(post[0])[0];

    var currChallenges = ch;
    // currChallenges.split(challenge);
    var newArray = currChallenges.filter((item) => item !== challenge);
    dispatch({ type: "SET_CURRENT_CHALLENGES", payload: newArray });

    console.log(randomInt);

    challengeText = challenge.challenge;
    challengePrompt = challenge.arbeitsauftrag;
    challengeType = challenge.challengeType;

    getChallenge(challengeText);
    getChallengeType(challengeType);
  }

  function updateCard() {
    SetChallenge();
    getChallenge(challengeText);
    getChallengeType(challengeType);
  }

  function GetNextChallengeOfType(typeOfChallenge) {
    // if (ch.length === 0) return;

    var dataArray = [...ch];

    // Step 1: Filter the array to include only entries with the desired challenge type
    var filteredData = dataArray.filter(
      (entry) => entry.challengeType === typeOfChallenge
    );

    var challengeTypeExists = filteredData.length > 0;

    console.log(challengeTypeExists);

    if (challengeTypeExists) {
      // Step 2: Generate a random index within the filtered array length
      var randomIndex = Math.floor(Math.random() * filteredData.length);

      // Step 3: Get the random entry from the filtered array
      var randomEntry = filteredData[randomIndex];

      var currChallenges = ch;

      var newArray = currChallenges.filter((item) => item !== randomEntry);
      dispatch({ type: "SET_CURRENT_CHALLENGES", payload: newArray });

      challengeText = randomEntry.challenge;
      challengePrompt = randomEntry.arbeitsauftrag;
      challengeType = randomEntry.challengeType;
      challengeStatus = "active";

      getChallenge(challengeText);
      getChallengeType(challengeType);
    } else {
      challengeText = "Ups, es gibt keine weitere Challenge von diesem Typ.";
      challengePrompt = "Wähle eine andere oder lade die Wortspiel-App neu.";
      challengeStatus = "finished";
      challengeType = typeOfChallenge;

      getChallenge(challengeText);
      getChallengeType(challengeType);
    }
  }

  return (
    <>
      <div className="all-types">
        <div className="section strength">
          <aside>
            <Card
              challengeText={chText}
              challengeType={chType}
              challengePrompt={challengePrompt}
              challengeId={challengeType}
              challengeStatus={challengeStatus}
              reloadFunction={reloadFunction}
            ></Card>
          </aside>
          <div>
            <div className="flex buttons">
              {/* <ChallengeFilter allChallenges={allChallenges} /> */}

              <Button
                handleClick={() => GetNextChallengeOfType("Pitch")}
                img={PitchIcon}
              />
              <Button
                handleClick={() => GetNextChallengeOfType("Quizshow")}
                img={QuizshowIcon}
              />
              <Button
                handleClick={() => GetNextChallengeOfType("Präsentation")}
                img={PresentationIcon}
              />
              <Button
                handleClick={() => GetNextChallengeOfType("Instant Story")}
                img={InstantStoryIcon}
              />
              <Button
                handleClick={() => GetNextChallengeOfType("Stammtisch")}
                img={StammtischIcon}
              />
              <Button
                handleClick={() => GetNextChallengeOfType("Lücke")}
                img={LückeIcon}
              />

              <div className="random">
                {" "}
                <Button
                  label="Random Challenge"
                  handleClick={updateCard}
                  img={RandomIcon}
                ></Button>
              </div>
            </div>

            <div classname="flex">
              <MailButton
                email="info@rulefactory.ch"
                subject="Wortspiel Challenge Vorschlag"
                body="Also, meine Idee ist: "
              >
                Challenge vorschlagen
              </MailButton>
            </div>
            <div>{/* <InstallPWA></InstallPWA> */}</div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
