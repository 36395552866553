import React from "react";
import reactStringReplace from "react-string-replace";
import ChallengeTypes from "./ChallengeTypes";
import { ReactComponent as ReactLogo } from "../images/wortspiel.svg";
import { ReactComponent as CardIcon } from "../images/card-icon.svg";
import { ReactComponent as ReloadIcon } from "../images/reload.svg";

var challengeText = "";
var challengeTypes = [
  "Keine Kategorie",
  "Instant Story",
  "Lücke",
  "Pitch",
  "Präsentation",
  "Quizshow",
  "Stammtisch",
];
var challengeDetail = "";
var challengePrompt = "";
var numOfCardsRendering = "";

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.challengeText = "Challenge Text";
    this.challengeType = "Challenge Type";
    this.challengePrompt = "Challenge Prompt";
    this.challengeStatus = "active";
    this.challengeId = 0;
    this.reloadFunction = null;
  }

  render() {
    challengeText = this.props.challengeText;

    // HANDLE "Blank" Type
    challengeText = reactStringReplace(challengeText, "[blank]", (match, i) => (
      <span className="blank"></span>
    ));

    // HANDLE Challenge Type Name
    var challengeType = this.props.challengeType; //challengeTypes[this.props.challengeType];

    // Handle CHALLENGE PROMPT
    if (this.props.challengePrompt != "") {
      challengePrompt = (
        <>
          <p className="prompt">{this.props.challengePrompt}</p>
        </>
      );
    } else {
      challengePrompt = "";
    }
    
    var isFinished = this.props.challengeStatus === "finished";
    // Handle Details
    if (this.props.challengeType === "Quizshow") {
      challengeDetail = (
        <>
          <div className="answers">
            <article>A</article>
            <article>B</article>
            <article>C</article>
            <article>D</article>
          </div>
          <p className="prompt">Begründe deine Antwort</p>
        </>
      );
    } else {
      challengeDetail = "";
    }
    
    if(isFinished)
      challengeDetail = "";

    // Handle Number of Cards
    var numOfCards = 0;

    if (
      ["Instant Story", "Lücke", "Stammtisch"].includes(
        this.props.challengeType
      )
    ) {
      numOfCards = 4;
    } else if (["Präsentation"].includes(this.props.challengeType)) {
      numOfCards = 3;
    }
    

    if (
      ["Instant Story", "Lücke", "Stammtisch", "Präsentation"].includes(
        this.props.challengeType
      )
    ) {
      numOfCardsRendering = (
        <>
          <div className="card-icon">
            {numOfCards}
            {/* <img src="https://wortspiel.app/wp-content/uploads/2022/09/card-icon.svg" /> */}
            <CardIcon></CardIcon>
          </div>
        </>
      );
    } else {
      numOfCardsRendering = "";
    }
    
    if(isFinished)
      numOfCardsRendering = "";
    

    return (
      <div className="card" key={this.challengeType}>
        <div className="card-content">
          <div className="text-center">
            {/* <a href="https://u20slam22.ch" target="_blank" rel="noopener"> */}
            <ReactLogo className="logo"></ReactLogo>
            {/* </a> */}
          </div>
          <div className="quiz">
            <h6>
              {!isFinished ? (
                <div>
                  {challengeType}
                  <ChallengeTypes chName={this.props.challengeId} />
                </div>
              ) : (
                "Oha!"
              )}
            </h6>
            <p>{challengeText}</p>
            {challengePrompt}
            {challengeDetail}
            {numOfCardsRendering}
            
            {isFinished ? (
              <div className="reload-icon"><a onClick={this.props.reloadFunction}><ReloadIcon /></a></div>
            ) : ("")}
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
