import React from "react";

const buttonStyle = {
  margin: "10px 0",
};

const Button = ({ handleClick, img: Img }) => {
  return (
    <a className="settings-icon next" onClick={handleClick}>
      {Img && <Img />}
    </a>
  );
};

export default Button;
